<script>
  import { onMount } from 'svelte';
  import { getContext } from 'svelte';
  import ReviewGridItem from '../components/ReviewGridItem.svelte';
  import ReviewListItem from '../components/ReviewListItem.svelte';
  import ReviewSummary from '../components/ReviewSummary.svelte';

  import { revConfig, tydal } from '../../config/storage';
  import { resizeDisplayListReviews } from '../resize';
  import { translations } from '../../config/translations';

  function openReviewWizardModal(){
    window.parent.postMessage({msg_action: "ba.ratings.write_review", msg_options: {}}, '*');
  }

  async function loadNextPage(){
    if (loading) {
      return;
    }

    loading = true;

    const data = await api.reviews(product.id, next_page);

    reviews = [...reviews, ...data.reviews];
    next_page = data.metadata.next_page;
    loading = false;
    if (next_page === null) {
      moreReviewsAvailable = false;
    }
    resizeDisplayListReviews();
  }
  
  const api = getContext('api');
  const product = $tydal.common.product;
  const productReviewData = product.review_data
  const reviewCount = productReviewData?.reviews_count || 0;
  const reviewStars = productReviewData?.stars || 0;
  const reviewsPerPage = parseInt($revConfig.widget_settings?.display_per_page || 20);

  let moreReviewsAvailable = reviewCount > reviewsPerPage;
  let reviews = productReviewData?.reviews;
  if (reviews){reviews.splice($revConfig.widget_settings?.display_per_page);}
  let next_page = 2;
  let loading = false;

  let ReviewItem;
  if ($revConfig.widget_settings?.display_list_type == 'list'){
    ReviewItem = ReviewListItem;
  } else {
    ReviewItem = ReviewGridItem;
  }

  onMount(() => {
    resizeDisplayListReviews();
    window.addEventListener("resize", resizeDisplayListReviews);

    setTimeout(function(){
      resizeDisplayListReviews();
    }, 200);

    setTimeout(function(){
      window.postMessage({msg_action: "event.resize"}, '*');
    }, 1500);
  });
</script>

<div id="panel-container" class="{$revConfig.widget_settings?.display_list_type}-view">
  <div id="panel-nav">
    <div class="summary-container">
      <ReviewSummary product={product} />
    </div>
    {#if $revConfig.widget_settings?.display_write_a_review_storefront_enabled}
      <div class="write-a-review-container">
        <button class="panel-btn rev-write-review"
          id="write-review"
          on:click={openReviewWizardModal}>
          {$translations.write_a_review}</button>
      </div>
    {/if}
  </div>
  
  {#if reviewCount > 0}
    <div id="review-panel">
      {#each reviews as review (review.id)}
        <svelte:component this={ReviewItem} review={review} />
      {/each}
    </div>
  {:else if $revConfig.widget_settings?.display_write_a_review_storefront_enabled}
    <p class="first-review-text">
      {$translations.first_person_to || "Be the first person to"} <a href="{'javascript:void(0)'}" on:click={openReviewWizardModal}>{$translations.write_a_review}</a>
    </p>
  {/if}

  {#if moreReviewsAvailable}
    <div class="load-more-container">
      <a id="rev-load-more" href="{'javascript:void(0)'}" class="panel-btn"
        on:click={loadNextPage}
        class:load-more-spinner={loading} 
        >
        <span class="load-more-text">{$translations.show_more_reviews}</span>
      </a>
    </div>
  {/if}

</div>
